import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {get, post} from '../../helpers/api_helper'

export const fetchAllCompanies = createAsyncThunk('fetch-all-companies', async (query) => {
  const response = await get(`/fetch-company-status-wise${query}`)
  return response
})

export const addCompany = createAsyncThunk('add-company', async (data) => {
  const response = await post('company-create-new', data)
  return response
})

export const editCompany = createAsyncThunk('update-company', async (data) => {
  const response = await post('company-update', data)
  return response
})

export const EditCompanyStatus = createAsyncThunk('update-company-status', async (data) => {
  const response = await post('update-company-status', data)
  return response
})

export const fetchCompanydetails = createAsyncThunk('fetch-company-details', async (company_id) => {
  const response = await get(`/fetch-company-details?company_id=${company_id}`)
  return response
})

export const EmailContactDuplicacy = createAsyncThunk('check-email-phone-duplicacy', async (data) => {
  const response = await post('/check-for-email-and-phone-duplicacy', data)
  return response
})
export const changeUserPassword = createAsyncThunk('admin-company-changepassword', async (data) => {
  const response = await post('/admin-company-change-password', data)
  return response
})

export const fetchChronTime = createAsyncThunk('fetchchrontime', async () => {
  const response = await get('/fetch-chron-job-time')
  return response
})

export const chronTimeCreate = createAsyncThunk('chrontime', async (data) => {
  const response = await post('/re-route-to-crm', data)
  return response
})

export const fetchCompanyContact = createAsyncThunk('/get-company-contact-details', async (query) => {
  const response = await get(`/get-company-contact-details${query}`)
  return response
})

export const fetchCompanyOnboardDeboard = createAsyncThunk('get-company-user-added-removed-details', async (query) => {
  const response = await get(`/get-company-user-added-removed-details${query}`)
  return response
})

export const fetchCompanyTransactionDetails = createAsyncThunk('fetch-company-transaction-details', async (query) => {
  const response = await get(`/admin/fetch-company-transaction-details${query}`)
  return response
})

export const fetchCompanyPlanHistory = createAsyncThunk('fetch-company-plan-history', async (query) => {
  const response = await get(`/admin/fetch-company-plan-history${query}`)
  return response
})

const companySlice = createSlice({
  name: 'company',
  initialState: {},
  reducers: {},
  extraReducers () {}
})

export default companySlice.reducer