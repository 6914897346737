import moment from 'moment'

export const PurchaseColumns = [
  {
    dataField: 'transactionId',
    text: 'Transaction ID',
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>
            {row.txnid}
          </div>
        </div>
      )
    }
  },
  {
    dataField: 'date',
    text: 'Date',
    style: { whiteSpace: 'nowrap' },
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>
            {row.created_at}
          </div>
        </div>
      )
    }
  },
  {
    dataField: 'transactionType',
    text: 'Transaction Type',
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>
            {row.cr_dr_type}
          </div>
        </div>
      )
    }
  },
  {
    dataField: 'credit',
    text: 'Credit Count',
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>
            {row.credit_balance}
          </div>
        </div>
      )
    }
  },
  {
    dataField: 'description',
    text: 'Description',
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>
            {row.description}
          </div>
        </div>
      )
    }
  },
  {
    dataField: 'status',
    text: 'Status',
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>
            {row.status}
          </div>
        </div>
      )
    }
  },
  {
    dataField: 'amount',
    text: 'Amount',
    style: { whiteSpace: 'nowrap' },
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>
            {row ? `${row['amount']} ${row['currency']}` : ''}
          </div>
        </div>
      )
    }
  },
  {
    dataField: 'remainingCredit',
    text: 'Remaining Credit',
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>
            {row.remaining_credit_balance}
          </div>
        </div>
      )
    }
  }
]

export const PeopleColumns = [
  {
    dataField: 'date',
    text: 'Date',
    style: { whiteSpace: 'nowrap' },
    formatter: (cell, row) => {
      const a = row.date ? row.date.split(' ') : ['0000-00-00']
      const b = a[0].split('-')

      return (
        <div className="table_row">
          <div>
            {b[0] === '0000' ? 'N/A' : moment(row['date']).format('DD/MM/YYYY')}
            {/* 08/02/2023 */}
          </div>
        </div>
      )
    }
  },
  {
    dataField: 'userName',
    text: 'User Name',
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>
            {row.user_name}
          </div>
        </div>
      )
    }
  },
  {
    dataField: 'action',
    text: 'Added/Removed',
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>
            {row.employee_current_status}
          </div>
        </div>
      )
    }
  }
]

export const PlanHistoryColumns = [

  {
    dataField: 'planName',
    text: 'Plan Name',
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>{row?.plan.name || 'N/A'}</div>
        </div>
      )
    }
  },
  {
    dataField: 'planStartDate',
    text: 'Plan Start Date',
    style: { whiteSpace: 'nowrap' },
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>{row?.start_date ? moment(row.start_date).format('DD/MM/YYYY') : 'N/A'}</div>
        </div>
      )
    }
  },
  {
    dataField: 'planEndDate',
    text: 'Plan End Date',
    style: { whiteSpace: 'nowrap' },
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>{row?.end_date ? moment(row.end_date).format('DD/MM/YYYY') : 'N/A'}</div>
        </div>
      )
    }
  },
  {
    dataField: 'planStatus',
    text: 'Plan Status',
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>{row?.status || 'N/A'}</div>
        </div>
      )
    }
  },
  {
    dataField: 'creditCostPerUser',
    text: 'Credit Cost Per User',
    formatter: (cell, row) => {
      return (
        <div className="table_row">
          <div>{row ? `₹${row.plan.company_plans['price']}` : 'N/A'}</div>
        </div>
      )
    }
  }
]
