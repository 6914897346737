import moment from 'moment'

export const renderColumns = () => {
  return (
    [
      {
        dataField: 'date',
        text: 'Date',
        style: { whiteSpace: 'nowrap' },
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {moment(row.created_at).format('DD-MM-YYYY')}
              </div>
            </div>
          )
        }
      },
      {
        dataField: 'companyName',
        text: 'Company Name',
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {row.company.name}
              </div>
            </div>
          )
        }
      },
      {
        dataField: 'creditCount',
        text: 'Credit Count',
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {row.credit_balance}
              </div>
            </div>
          )
        }
      },
      {
        dataField: 'currentCreditCount',
        text: 'Current Credit Count',
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {row.remaining_credit_balance}
              </div>
            </div>
          )
        }
      },
      {
        dataField: 'amountWithoutTax',
        text: 'Amount without Tax',
        style: { whiteSpace: 'nowrap' },
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {row ? `${row['amount']} ${row['currency']}` : ''}
              </div>
            </div>
          )
        }
      },
      {
        dataField: 'taxAmount',
        text: 'Tax Amount',
        style: { whiteSpace: 'nowrap' },
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {row ? `${row['tax_amount']} ${row['currency']}` : ''}
              </div>
            </div>
          )
        }
      },
      {
        dataField: 'amountWithTax',
        text: 'Amount with Tax',
        style: { whiteSpace: 'nowrap' },
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {row ? `${row['amount_with_tax']} ${row['currency']}` : ''}
              </div>
            </div>
          )
        }
      },
      {
        dataField: 'paymentMode',
        text: 'Payment Mode',
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {row.payment_mode ? row.payment_mode : 'N/A'}
              </div>
            </div>
          )
        }
      },
      {
        dataField: 'paymentGateway',
        text: 'Payment Gateway',
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {row.payment_gateway ? row.payment_gateway.name : 'N/A'}
              </div>
            </div>
          )
        }
      },
      {
        dataField: 'transactionId',
        text: 'Transaction ID',
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {row.txnid ? row.txnid : 'N/A' }
              </div>
            </div>
          )
        }
      },
      {
        dataField: 'paymentType',
        text: 'Payment Type',
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {row.cr_dr_type === 'Dr' ? 'Debit' : 'Credit'}
              </div>
            </div>
          )
        }
      },
      {
        dataField: 'paymentStatus',
        text: 'Payment Status',
        formatter: (cell, row) => {
          return (
            <div className="table_row">
              <div>
                {row.status}
              </div>
            </div>
          )
        }
      }
    ]
  )

}

export const PaymentMode = [
  {
    name: 'Cr',
    label: 'Credit'
  },
  {
    name: 'Dr',
    label: 'Debit'
  }
]
export const PaymentStatus = [
  {
    name: 'SUCCESS',
    label: 'Success'
  },
  {
    name: 'PENDING',
    label: 'Pending'
  },
  {
    name: 'FAILED',
    label: 'Failed'
  }
]