import { Autocomplete, Stack, TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import {
  Col,
  Container,
  Row,
  Spinner
} from 'reactstrap'
import {
  PaymentMode,
  PaymentStatus,
  renderColumns
} from './columns'
import Flatpickr from 'react-flatpickr'
import { useDispatch } from 'react-redux'
import {
  fetchCompanyList,
  fetchRenewalPaymentList
} from '../../store/plan'
import { toast } from 'react-toastify'
import moment from 'moment'

const Payments = () => {
  const dispatch = useDispatch()
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [companylist, setCompanyList] = useState([])
  const [companyId, setCompanyId] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState('')
  const [transactionType, setTransactionType] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isCalenderOpen, setIsCalenderOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchList = async (pageValue, perPageValue) => {
    setLoading(true)
    try {
      const payload = {
        company_id: companyId ? companyId.id : null,
        start_date: startDate,
        end_date: endDate,
        payment_type: transactionType ? transactionType.name : '',
        status: paymentStatus ? paymentStatus.name : ''
      }
      const data = {
        payload,
        query: `?page=${pageValue}&limit=${perPageValue}`
      }
      const res = await dispatch(fetchRenewalPaymentList(data)).unwrap()
      if (res?.status?.success) {
        setList(res.data.results)
        setCount(res.data.count)
      }
    } catch (err) {
      toast.error(err.message)
    }
    setLoading(false)
  }

  const fetchCompanyListData = async () => {
    try {
      const res = await dispatch(fetchCompanyList()).unwrap()
      if (res?.status?.success) {
        const data = res.data.map((o) => {
          return {
            id: o.id,
            label: o.name
          }
        })
        setCompanyList(data)
      }
    } catch (err) {
      toast.error(err.message)
    }
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setPage(page)
    setPerPage(sizePerPage)
    fetchList(page, sizePerPage)
  }

  const setDateRange = (event) => {
    if (event?.length === 2) {
      setStartDate(moment(event[0]).format('YYYY-MM-DD'))
      setEndDate(moment(event[1]).format('YYYY-MM-DD'))
    } else if (event?.length === 0) {
      setStartDate('')
      setEndDate('')
    }
  }

  useEffect(() => {
    setPage(1)
    fetchList(1, perPage)
  }, [companyId, paymentStatus, transactionType, startDate, endDate])

  useEffect(() => {
    fetchCompanyListData()
    fetchList(1, 10)
  }, [])
  const fp = useRef(null)

  return (
    <div className="page-content">
      <Container fluid>
        <Row className='justify-content-between'>
          <Col>
            <h4 className='mb-0'>Payment Information</h4>
          </Col>
        </Row>
        <Row style={{margin:'20px 0 40px 0', rowGap:'1rem'}} className='payment-filter-fields'>
          <Col lg={3} md={6} sm={12} className='position-relative ps-0'>
            <Flatpickr
              className="form-control bg-transparent custom-date-range"
              placeholder="Select date range"
              ref={fp}
              options={{
                mode: 'range',
                dateFormat: 'd M, Y',
                maxDate:'today',
                onReady: () => {
                  const monthContainer = document.querySelector('.flatpickr-months')
                  if (monthContainer) {
                    monthContainer.classList.add('custom-month-header')
                  }
                }
              }}
              onChange={(event) => {
                setDateRange(event)
              }}
            />
            <Stack flexDirection={'row'} className='calender-action-btns'>
              {startDate &&
              <div className='calender-icon'
                onClick={() => { fp.current.flatpickr.clear() }}><i className="ri-close-line"></i></div>
              }
              <div className='calender-icon'
                onClick={() => {
                  isCalenderOpen ? fp.current.flatpickr.close() : fp.current.flatpickr.open()
                  setIsCalenderOpen(!isCalenderOpen)
                }}>📅</div>
            </Stack>
          </Col>
          <Col lg={3} md={6} sm={12} className='ps-0'>
            <Autocomplete
              size='small'
              disablePortal
              value={companyId}
              options={companylist}
              fullWidth
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.label}
                  </li>
                )
              }}
              onChange={(event, value) => {
                if (value) {
                  setCompanyId(value)
                } else {
                  setCompanyId(null)
                }
              }}
              renderInput={(params) => <TextField {...params} label="Search by company name" />}
            />
          </Col>
          <Col lg={3} md={6} sm={12} className='ps-0'>
            <Autocomplete
              size='small'
              disablePortal
              options={PaymentMode}
              fullWidth
              onChange={(event, value) => {
                if (value) {
                  setTransactionType(value)
                } else {
                  setTransactionType('')
                }
              }}
              renderInput={(params) => <TextField {...params} label="Select Payment Type" />}
            />
          </Col>
          <Col lg={3} md={6} sm={12} className='ps-0'>
            <Autocomplete
              size='small'
              disablePortal
              options={PaymentStatus}
              fullWidth
              onChange={(event, value) => {
                if (value) {
                  setPaymentStatus(value)
                } else {
                  setPaymentStatus('')
                }
              }}
              renderInput={(params) => <TextField {...params} label="Select Payment Status" />}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} className='payment-table'>
            <BootstrapTable
              remote={{ pagination: true }}
              classes="react-bootstrap-table background-table scroll-table"
              keyField="id"
              pagination={paginationFactory({
                sizePerPageList: [10, 20, 50],
                page,
                sizePerPage: perPage,
                showTotal: true,
                totalSize: count,
                alwaysShowAllBtns: true
              })}
              data={list}
              columns={renderColumns()}
              bordered={false}
              striped={false}
              headerClasses={'table_head_background_color table_background'}
              rowClasses={
                'font-family font_size_4 color_main_text table_border_bottom'
              }
              onTableChange={handleTableChange}
              responsive
              noDataIndication={loading ? <div className="d-flex justify-content-center align-items-center w-100"> <Spinner type="grow" className="m-1 d-flex justify-content-center align-items-center" color="primary" /></div> : 'No Data to Show!'}
              // {...toolkitProps.baseProps}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Payments
