import React, { useState, useEffect, useCallback } from 'react'
import { Container, Row, Col, Button, Spinner, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap'
import _debounce from 'lodash/debounce'
import UpdateAdminPassword from './UpdateAdminPassword'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import MoveToActiveDialog from './MoveToActiveDialog'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import paginationFactory from 'react-bootstrap-table2-paginator'
import AddEdit  from './AddEditCompany'
import { fetchAllCompanies, EditCompanyStatus } from '../../../store/company'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import CompanyDetail from './CompanyDetail'

const CompaniesList = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  document.title = params.type === 'ACT' ? 'TeamTrace | Admin Active Companies' : 'TeamTrace | Admin InActive Companies'

  const [loading, setLoading] = useState(true)
  const [companyList, setCompanyList] = useState([])
  const [companyDetails] = useState({})
  const [id, setId] = useState('')
  const [status, setStatus] = useState('')
  const [isRight, setIsRight] = useState(false)
  const [isActive, setIsActiveModal] = useState(false)
  const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState(false)
  const [search, setSearch] = useState('')
  const [type, setType] = useState('')
  const [count, setCount] = useState(0)
  const [openDrawer, setOpenDrawer] = useState(false)

  const handleOpenUpdatePasswordModal = (id) => {
    setOpenUpdatePasswordModal(!openUpdatePasswordModal)
    setId(id)
  }

  const closePasswordmodal = (val) => {
    setOpenUpdatePasswordModal(val)
  }

  const toggleRightSidebar = () => {
    setIsRight(!isRight)
  }

  function goUpdatePage (id) {
    //history.push(`/edit_skill/${id}`);
    setId(id)
    setIsRight(!isRight)
  }
  const moveToInActive = (rowId, companyStatus) => {
    setId(rowId)
    companyStatus === 'ACT' ? setStatus('I') : setStatus('A')
    setIsActiveModal(!isActive)
  }

  const updateCompanyStatus = async () => {
    try {
      const obj = {
        company_id:id,
        company_status:status
      }
      const response = await dispatch(EditCompanyStatus(obj)).unwrap()
      if (response?.status?.success) {
        toast.success(response.message)
        navigate(`/companies_list/${params.type}`)
        await fetchCompanies()
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  const fetchCompanies = async (name = null, type = params.type) => {
    try {
      setLoading(true)
      const companyStatusValue = `?company_status=${type === 'ACT' ? 'A' : 'I'}`
      let companyNameValue = ''
      if (name) {
        companyNameValue = `&name=${name}`
      }
      const option = companyStatusValue + companyNameValue
      const response = await dispatch(fetchAllCompanies(option)).unwrap()
      if (response?.status?.success) {
        setCompanyList(response.data.companies)
        setCount(response?.data?.count)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
    setLoading(false)
  }
  function searchData (searchValue, type) {
    try {

      fetchCompanies(searchValue, type)
    } catch (ex) {
      toast.error(ex.message)
    }
  }
  const debounceSearchTask = useCallback(_debounce(searchData, 500), [])
  const columnDefActiveAndInactive = [
    {
      dataField: 'company_name',
      text: 'Company Name',
      // sort: true,
      formatter: (cell, row) => {
        return (
          <div className="table_row hover-text" onClick={() => { setOpenDrawer(true); setId(row.id) }}>
            {row?.name || ''}
          </div>
        )
      }
    },
    {
      dataField: 'company_contact_Number',
      text: 'Contact Number',
      // sort: true,
      formatter: (cell, row) => {
        return (
          <div className="table_row">
            <div>
              {row['primary_phone']}
              {/* 9830456320 */}
            </div>
          </div>
        )
      }
    },
    {
      dataField: 'state',
      text: 'State',
      // sort: true,
      formatter: (cell, row) => {
        const statedata = row.company_branches.length > 0 && row.company_branches[0].state_id

        return (
          <div className="table_row">
            <div>

              {/* {row["company_branches.state.name"]} */}

              {statedata ? statedata['name'] : ''}

            </div>
          </div>
        )
      }
    },
    {
      dataField: 'city',
      text: 'City',
      // sort: true,
      formatter: (cell, row) => {
        const citydata = row.company_branches.length > 0 && row.company_branches[0].city_id

        return (
          <div className="table_row">
            <div>
              {citydata ? citydata['name'] : ''}
            </div>
          </div>
        )
      }
    },
    {
      dataField: 'employess',
      text: 'Employees',
      // sort: true,
      formatter: (cell, row) => {
        return (
          <div className="table_row">
            <div>
              {row.users.length > 0 ? row.users.length : 0}
            </div>
          </div>
        )
      }
    },
    {
      dataField: 'plans',
      text: 'Plan',
      // sort: true,
      formatter: (cell, row) => {
        const plandata = row.company_plans.length && row.company_plans.map((item) => {
          return item?.plan?.name
        })

        return (
          <div className="table_row">
            <div>
              {plandata ? plandata : 'N/A'}
            </div>
          </div>
        )
      }
    },
    {
      dataField: 'date_created',
      text: 'Date Created',
      // sort: true,
      formatter: (cell, row) => {
        const a = row.created_at ? row.created_at.split(' ') : ['0000-00-00']
        const b = a[0].split('-')

        return (
          <div className="table_row">
            <div>
              {b[0] === '0000' ? 'N/A' : moment(row['created_at']).format('DD/MM/YYYY')}
              {/* 08/02/2023 */}
            </div>
          </div>
        )
      }
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) => {
        return (
          <div>
            {/* <i className="bx bx-trash-alt pe-3 delete icon font-size-16" role="button"></i> */}
            <i
              className="bx bx-pencil pe-3 ion-button edit icon font-size-16"
              role="button"
              onClick={() => {
                goUpdatePage(row.id)
              }}
            ></i>
            {params.type === 'ACT' ?
              <i className="ri-lock-line pe-3 action-button" role="button"

                onClick={() => {
                  handleOpenUpdatePasswordModal(row.id)
                }}
              ></i> : null}
            <i className="ri-arrow-right-circle-line" role="button"
              onClick={() => {
                moveToInActive(row.id, params.type)
              }}
            ></i>
          </div>
        )
      }
    }
  ]

  useEffect(() => {
    setLoading(true)
    setType(params.type)
    setCompanyList([])
    fetchCompanies()
    setSearch('')
  }, [params.type])

  const handleClose = () => {
    setIsRight(false)
  }
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div>
            <div style={{marginBottom:'40px'}} className='d-flex justify-content-between align-items-center'>
              {/* <div>
                {params.type === 'ONB' ? (
                  <BreadCrumb title="On-Boarding"/>
                ) : params.type === 'ACT' ? (
                  <BreadCrumb title="Active"/>
                ) : (
                  <BreadCrumb title="Inactive"/>
                )}
              </div> */}
              <div>
                {params.type === 'ONB' ? (
                  <h4 className='mb-0'>On-Boarding</h4>
                ) : params.type === 'ACT' ? (
                  <h4 className='mb-0'>Active</h4>
                ) : (
                  <h4 className='mb-0'>Inactive</h4>
                )}
              </div>
              <div
                // md={6}
                // xl={6}
                className="mb-0"
              >
                <div className="d-flex">

                  <TextField
                    className='ms-2'
                    placeholder="Search By company name"
                    id="outlined-size-small"
                    size="small"
                    autoComplete="off"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value)
                      debounceSearchTask(e.target.value, params.type)
                    }}
                    InputProps={{
                      startAdornment:
                        <InputAdornment position="start"><SearchIcon />
                        </InputAdornment>
                    }}
                  />

                  {params.type === 'ACT' && (
                    <Button
                      className="button_cls" style={{marginLeft:'8px', width:'150px'}}
                      onClick={() => {
                        toggleRightSidebar()
                        setId('')
                      }}
                    >
                      <div className="d-flex align-items-center" style={{ gap: '5px' }}>
                        <i className="mdi mdi-plus fa-lg"></i>
                        <span>Add Company</span>
                      </div>
                    </Button>
                  )}
                </div>
              </div>

            </div>
          </div>
          <Row>
            <Col xl={12}>

              <BootstrapTable
                classes="react-bootstrap-table background-table"
                //  pagination={paginationFactory({
                //               page: page,
                //               sizePerPage: limit,
                //               totalSize: count,
                //               showTotal: true,
                //               alwaysShowAllBtns: true,
                //               sizePerPageList: [10, 20, 50],
                //               // onPageChange: (e) => {
                //               //     pageChange(e, limit);
                //               // },
                //               // onSizePerPageChange: (e) => {
                //               //     sizeChange(e);
                //               // },
                //             })}
                keyField="id"
                pagination={paginationFactory({sizePerPageList: [10, 20, 50],
                  showTotal: true,
                  totalSize: count,
                  alwaysShowAllBtns: true})}
                data={companyList}
                columns={columnDefActiveAndInactive}
                headerClasses={'table_head_background_color table_background'}
                rowClasses={
                  'font-family font_size_4 color_main_text table_border_bottom'
                }
                noDataIndication={loading ? <div className="d-flex justify-content-center align-items-center w-100"> <Spinner type="grow" className="m-1 d-flex justify-content-center align-items-center" color="primary" /></div> : 'No Data to Show!'}

              />
            </Col>

          </Row>
        </Container>
      </div>
      {openUpdatePasswordModal ?
        <UpdateAdminPassword
          show={openUpdatePasswordModal}
          handleClose={closePasswordmodal}
          editid={id}
        /> : null}
      <MoveToActiveDialog
        updateCompanyStatus={updateCompanyStatus}
        companyName={companyDetails.name}
        toggle={moveToInActive}
        modal={isActive}
        status={ params.type === 'ACT' ? 'InActive' : 'Active'}
      />
      {isRight ?
        <AddEdit
          isRight={isRight}
          handleClose={handleClose}
          editid={id}
          toggle={toggleRightSidebar}
          type={params.type}
          fetchCompanies={() => fetchCompanies(search, type)}

        /> : null}
      <Offcanvas
        isOpen={openDrawer}
        toggle={toggleDrawer}
        direction="end"
        onExit={() => setOpenDrawer(!openDrawer)}
        className="offcanvas-end border-0 payment-offcanvas">
        <OffcanvasHeader className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark" toggle={toggleDrawer}>
          <span className="m-0 me-2 text-white">Company Details</span>
        </OffcanvasHeader>
        <OffcanvasBody className='p-0'>
          <CompanyDetail companyId={id} />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}

export default CompaniesList
