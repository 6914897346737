import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchCompanyContact, fetchCompanyOnboardDeboard, fetchCompanyTransactionDetails, fetchCompanyPlanHistory } from '../../../store/company' // Adjust the import path as necessary
import { Stack, Typography } from '@mui/material'
import { Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import { PeopleColumns, PlanHistoryColumns, PurchaseColumns } from './Columns'
import { toast } from 'react-toastify'

const CompanyDetail = ({ companyId }) => {

  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('1')
  const [loading, setLoading] = useState(true)
  const [companyDetails, setCompanyDetails] = useState(null)

  const [peoplePage, setPeoplePage] = useState(1)
  const [peoplePerPage, setPeoplePerPage] = useState(10)
  const [peopleCount, setPeopleCount] = useState(0)
  const [PeopleData, setPeopleData] = useState([])

  const [transactionPage, setTransactionPage] = useState(1)
  const [transactionPerPage, setTransactionPerPage] = useState(10)
  const [transactionCount, setTransactionCount] = useState(0)
  const [PurchaseData, setPurchaseData] = useState([])

  const [planHistoryPage, setPlanHistoryPage] = useState(1)
  const [planHistoryPerPage, setPlanHistoryPerPage] = useState(10)
  const [planHistoryCount, setPlanHistoryCount] = useState(0)
  const [PlanHistoryData, setPlanHistoryData] = useState([])

  const Tabs = ['Contact Details', 'Company\'s Purchases', 'People Addition/Removal', 'Plan History']

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const fetchCompanyDetails = async () => {
    try {
      const response = await dispatch(fetchCompanyContact(`?company_id=${companyId}`)).unwrap()
      if (response?.status?.success) {
        setCompanyDetails(response.data)
      }
    } catch (error) {
      toast.error(error?.status?.message)
    }
  }

  const fetchPeopleData = async (pageValue, perPageValue) => {
    try {
      setLoading(true)
      const response = await dispatch(fetchCompanyOnboardDeboard(`?company_id=${companyId}&page=${pageValue}&limit=${perPageValue}`)).unwrap()
      if (response?.status?.success) {
        setPeopleData(response.data.results)
        setPeopleCount(response.data.count)
      }
    } catch (error) {
      toast.error(error.status.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchTransactionData = async (pageValue, perPageValue) => {
    try {
      setLoading(true)
      const response = await dispatch(fetchCompanyTransactionDetails(`?company_id=${companyId}&page=${pageValue}&limit=${perPageValue}`)).unwrap()
      if (response?.status?.success) {
        setPurchaseData(response.data.results)
        setTransactionCount(response.data.count)
      }
    } catch (error) {
      toast.error(error.status.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchPlanHistoryData = async (pageValue, perPageValue) => {
    try {
      setLoading(true)
      const response = await dispatch(fetchCompanyPlanHistory(`?company_id=${companyId}&page=${pageValue}&limit=${perPageValue}`)).unwrap()
      if (response?.status?.success) {
        setPlanHistoryData(response.data.results)
        setPlanHistoryCount(response.data.count)
      }
    } catch (error) {
      toast.error(error.status.message)
    } finally {
      setLoading(false)
    }
  }

  const handlePeopleTableChange = (type, { page, sizePerPage }) => {
    setPeoplePage(page)
    setPeoplePerPage(sizePerPage)
    fetchPeopleData(page, sizePerPage)
  }

  const handleTransactionTableChange = (type, { page, sizePerPage }) => {
    setTransactionPage(page)
    setTransactionPerPage(sizePerPage)
    fetchTransactionData(page, sizePerPage)
  }

  const handlePlanTableChange = (type, { page, sizePerPage }) => {
    setPlanHistoryPage(page)
    setPlanHistoryPerPage(sizePerPage)
    fetchPlanHistoryData(page, sizePerPage)
  }

  useEffect(() => {
    fetchCompanyDetails()
    fetchPeopleData(peoplePage, peoplePerPage)
    fetchTransactionData(transactionPage, transactionPerPage)
    fetchPlanHistoryData(planHistoryPage, planHistoryPerPage)
  }, [])

  return (
    <div className="dropdown-head payment-tabs">
      <Nav className="px-2 pt-2 nav-tabs bg-primary dropdown-tabs nav-tabs-custom">
        {Tabs.map((name, index) => {
          return (
            <NavItem key={index}>
              <NavLink
                href="#"
                className={classnames({ active: activeTab === String(index + 1) })}
                onClick={() => { toggleTab(String(index + 1)) }}
              >
                {name}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1" className="p-4">
          <Stack className='gap-2 mb-4'>
            <div xl="12" className=" font_weight_2">
              <strong style={{ fontSize: '15px' }}>Company</strong>
            </div>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant="subtitle1" width={'30%'} >
                Contact Number
              </Typography>:&nbsp;&nbsp;&nbsp;
              <Typography variant="subtitle1" fontWeight={600}>
                {companyDetails?.primary_phone || 'N/A'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant="subtitle1" width={'30%'} >
                Email
              </Typography>:&nbsp;&nbsp;&nbsp;
              <Typography variant="subtitle1" fontWeight={600}>
                {companyDetails?.primary_email || 'N/A'}
              </Typography>
            </Stack>
          </Stack>

          <Stack className='gap-2'>
            <div className="font_weight_2">
              <strong style={{ fontSize: '15px' }}>Admin</strong>
            </div>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant="subtitle1" width={'30%'} >
                Full Name
              </Typography>:&nbsp;&nbsp;&nbsp;
              <Typography variant="subtitle1" fontWeight={600}>
                {companyDetails?.users?.name || 'N/A'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant="subtitle1" width={'30%'} >
                Contact Number
              </Typography>:&nbsp;&nbsp;&nbsp;
              <Typography variant="subtitle1" fontWeight={600}>
                {companyDetails?.users?.contact_number || 'N/A'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant="subtitle1" width={'30%'} >
                Email
              </Typography>:&nbsp;&nbsp;&nbsp;
              <Typography variant="subtitle1" fontWeight={600}>
                {companyDetails?.users?.email || 'N/A'}
              </Typography>
            </Stack>
          </Stack>
        </TabPane>
        <TabPane tabId="2" className="p-4">
          <div className='m-2 pt-3'>
            <BootstrapTable
              remote={{ pagination: true }}
              classes="react-bootstrap-table background-table scroll-table"
              keyField="txnid"
              data={PurchaseData}
              columns={PurchaseColumns}
              bordered={false}
              striped={false}
              headerClasses={'table_head_background_color table_background'}
              rowClasses={'font-family font_size_4 color_main_text table_border_bottom'}
              responsive
              pagination={paginationFactory({
                sizePerPageList: [10, 20, 50],
                page: transactionPage,
                sizePerPage: transactionPerPage,
                totalSize: transactionCount,
                alwaysShowAllBtns: true
              })}
              onTableChange={handleTransactionTableChange}
              noDataIndication={loading ? <div className="d-flex justify-content-center align-items-center w-100"> <Spinner type="grow" className="m-1 d-flex justify-content-center align-items-center" color="primary" /></div> : 'No Data to Show!'}
            />
          </div>
        </TabPane>
        <TabPane tabId="3" className="p-4">
          <div className='m-2 pt-3'>
            <BootstrapTable
              remote={{ pagination: true }}
              classes="react-bootstrap-table background-table"
              keyField="id"
              data={PeopleData}
              columns={PeopleColumns}
              bordered={false}
              striped={false}
              headerClasses={'table_head_background_color table_background'}
              rowClasses={'font-family font_size_4 color_main_text table_border_bottom'}
              responsive
              pagination={paginationFactory({
                sizePerPageList: [10, 20, 50],
                page: peoplePage,
                sizePerPage: peoplePerPage,
                totalSize: peopleCount,
                alwaysShowAllBtns: true
              })}
              onTableChange={handlePeopleTableChange}
              noDataIndication={loading ? <div className="d-flex justify-content-center align-items-center w-100"> <Spinner type="grow" className="m-1 d-flex justify-content-center align-items-center" color="primary" /></div> : 'No Data to Show!'}
            />
          </div>
        </TabPane>
        <TabPane tabId="4" className="p-4">
          <div className='m-2 pt-3'>
            <BootstrapTable
              remote={{ pagination: true }}
              classes="react-bootstrap-table background-table"
              keyField="id"
              data={PlanHistoryData}
              columns={PlanHistoryColumns}
              bordered={false}
              striped={false}
              headerClasses={'table_head_background_color table_background'}
              rowClasses={'font-family font_size_4 color_main_text table_border_bottom'}
              responsive
              pagination={paginationFactory({
                sizePerPageList: [10, 20, 50],
                page: planHistoryPage,
                sizePerPage: planHistoryPerPage,
                totalSize: planHistoryCount,
                alwaysShowAllBtns: true
              })}
              onTableChange={handlePlanTableChange}
              noDataIndication={loading ? <div className="d-flex justify-content-center align-items-center w-100"> <Spinner type="grow" className="m-1 d-flex justify-content-center align-items-center" color="primary" /></div> : 'No Data to Show!'}
            />
          </div>
        </TabPane>
      </TabContent>
    </div >
  )
}

export default CompanyDetail